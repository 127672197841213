import { Component, createRef } from 'react'
import withRouter from '../components/withRouter'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import TopMenu from '../components/topMenu'
import BottomMenu from '../components/bottomMenu'
import MiniWide from '../components/miniWide'
import Card from '../components/card'
import { TbFilterSearch } from 'react-icons/tb'
import { API } from 'aws-amplify'
import Beer from '../images/beer.png'
import Food from '../images/food.png'
import Mic from '../images/mic.png'
import Crowd from '../images/crowd.png'
import Venue from '../images/venue.png'
import Comedy from '../images/comedy.png'
import Music from '../images/music.png'
import Church from '../images/chruch.png'
import Club from '../images/club.png'
import DJ from '../images/dj.png'
import './admin.css'

class Fan extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            lastSearch: { dist: 25, inactive: true },
            loc: '',
            lastLoc: '',
            locIsGood: false,
            dist: 25,
            distVal: 250,
            center: null,
            options: null,
            pref: null,
            name: '',
            node: null,
            searchChanged: false,
            menu: {
                left: 'login',
                giglit: null,
                right: 'reset',
                status: null
            },
            filteredData: null,
            contributor: null,
            venue: null,
            zoom: 10,
            displaySearch: false,
            inactive: true,
            next: null,
            isNext: false
        }
        this.handleLocationCheck = this.handleLocationCheck.bind(this)
        this.handleLocChanged = this.handleLocChanged.bind(this)
        this.handleDistChanged = this.handleDistChanged.bind(this)
        this.handleOnNotificationsSelect = this.handleOnNotificationsSelect.bind(this)
        this.handleSearch = this.handleSearch.bind(this)
        this.handleOnPrefSelect = this.handleOnPrefSelect.bind(this)
        this.handleOptionSelect = this.handleOptionSelect.bind(this)
        this.handleGetMyLoc = this.handleGetMyLoc.bind(this)
        this.handleLeftMenu = this.handleLeftMenu.bind(this)
        this.handleRightMenu = this.handleRightMenu.bind(this)
        this.handleGoToCard = this.handleGoToCard.bind(this)
        this.handleDisplaySearch = this.handleDisplaySearch.bind(this)
        this.handleResetOptions = this.handleResetOptions.bind(this)
        this.handleNameChange = this.handleNameChange.bind(this)
        this.handleInactive = this.handleInactive.bind(this)
        this.handleClearDist = this.handleClearDist.bind(this)
        this.handleTest = this.handleTest.bind(this)
    }

    FanCards = createRef()

    componentDidMount() {
        const { search } = this.props
        const queryParams = new URLSearchParams(search.search)
        const refresh = queryParams.get('refresh') || false
        if (!this.props.options) this.getOptions()
        if (!this.props.data || refresh) {
            if (this.props.myloc) {
                this.setState({ center: this.props.myLoc, dist: this.props.user && this.props.user.dist ? this.props.user.dist : this.state.dist, distVal: this.props.user && this.props.user.dist ? this.props.user.dist * 10 : this.state.dist * 10, menu: { ...this.state.menu, left: this.props.isAuthenticated ? 'logout' : 'login' } })
                this.getData(this.props.myLoc, this.props.user && this.props.user.dist ? this.props.user.dist : this.state.dist)
            } else {
                const gl = navigator.geolocation
                gl.getCurrentPosition(position => {
                    this.props.onMyLoc({ lat: position.coords.latitude, lng: position.coords.longitude })
                    this.setState({ center: { lat: position.coords.latitude, lng: position.coords.longitude }, isMounting: false, dist: this.props.user && this.props.user.dist ? this.props.user.dist : this.state.dist, distVal: this.props.user && this.props.user.dist ? this.props.user.dist * 10 : this.state.dist * 10, menu: { ...this.state.menu, left: this.props.isAuthenticated ? 'logout' : 'login' } })
                    this.getData({ lat: position.coords.latitude, lng: position.coords.longitude }, this.props.user && this.props.user.dist ? this.props.user.dist : this.state.dist)
                }, err => {
                    if (this.props.user && this.props.user.defaultLoc) {
                        this.props.onMyLoc(this.props.user.defaultLoc)
                        this.setState({ center: this.props.user.defaultLoc, dist: this.props.user && this.props.user.dist ? this.props.user.dist : this.state.dist, distVal: this.props.user && this.props.user.dist ? this.props.user.dist * 10 : this.state.dist * 10, menu: { ...this.state.menu, left: this.props.isAuthenticated ? 'logout' : 'login' } })
                        this.getData(this.props.user.defaultLoc, this.props.user.dist || this.state.dist)
                    } else this.setState({ isLoading: false, menu: { ...this.state.menu, left: this.props.isAuthenticated ? 'logout' : 'login' } })
                })
            }
        } else {
            if (this.props.filter) {
                this.setState({
                    isLoading: false,
                    center: this.props.myLoc,
                    loc: this.props.filter.loc || '',
                    dist: this.props.filter.dist || (this.props.user && this.props.user.dist ? this.props.user.dist : this.state.dist),
                    distVal: this.props.filter.dist ? this.props.filter.dist * 10 : (this.props.user && this.props.user.dist ? this.props.user.dist * 10 : this.state.dist * 10),
                    pref: this.props.filter.pref || null,
                    menu: { ...this.state.menu, left: this.props.isAuthenticated ? 'logout' : 'login' }
                })
            } else {
                this.setState({ isLoading: false, center: this.props.myLoc, dist: this.props.user && this.props.user.dist ? this.props.user.dist : this.state.dist, distVal: this.props.user && this.props.user.dist ? this.props.user.dist * 10 : this.state.dist * 10, menu: { ...this.state.menu, left: this.props.isAuthenticated ? 'logout' : 'login' } })
            }
            this.handleFilter(null, this.props.filter ? (this.props.filter.pref || null) : this.state.pref)
        }
    }

    handleDisplaySearch() {
        if (this.state.displaySearch) {
            this.handleSearch()
            this.setState({ displaySearch: false })
        } else {
            if (!this.props.options && this.props.isAuthenticated) {
                this.getOptions()
            }
            this.setState({ displaySearch: true })
        }
    }

    handleGetMyLoc() {
        const gl = navigator.geolocation
        gl.getCurrentPosition(position => {
            API.get('lt', `geo?coord=${position.coords.latitude},${position.coords.longitude}`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                .then(resp => {
                    if (resp.data && resp.data.results[0]) {
                        const loc = resp.data.results[0].formatted_address
                        this.props.onMyLoc(resp.data.results[0].geometry.location)
                        this.setState({ loc, locIsGood: true, lastLoc: loc, center: resp.data.results[0].geometry.location, searchChanged: true })
                    } else {
                        this.setState({ locIsGood: false, loc: 'Not Found' })
                    }
                })
        }, err => {
            console.log(err)
            this.setState({ locIsGood: false, loc: 'Not Found' })
        })
    }

    handleLocationCheck() {
        if (this.state.loc && this.state.loc !== this.state.lastLoc) {
            API.get('lt', `geo?address=${this.state.loc.replace(/#/g, '%23')}`, { headers: { Authorization: `Basic VSQzcm5hbWU6UGEkc3dvcmQ=` } })
                .then(resp => {
                    if (resp.data && resp.data.results[0]) {
                        const loc = resp.data.results[0].formatted_address
                        this.props.onMyLoc(resp.data.results[0].geometry.location)
                        this.setState({ loc, locIsGood: true, lastLoc: loc, center: resp.data.results[0].geometry.location, searchChanged: true })
                    }
                })
                .catch(e => {
                    this.setState({ loc: '', locIsGood: false })
                })
        }
    }

    handleDistChanged(event) {
        let dist = event.target.value === 0 ? 10 : event.target.value
        this.setState({ dist, distVal: dist * 10 })
    }

    handleClearDist() {
        this.setState({ dist: false })
    }

    handleLocChanged(event) {
        this.setState({ locIsGood: false, loc: event.target.value.replace(/[^a-zA-Z0-9-, ]/g, "") })
    }

    handleSearch() {
        let doIt = false
        if (this.state.lastSearch) {
            if (this.state.lastSearch.dist && this.state.lastSearch.dist !== this.state.dist) {
                doIt = true
            } else if (this.state.lastSearch.inactive !== this.state.inactive) {
                doIt = true

            } else if (this.state.searchChanged) {
                doIt = true
            } else if (this.state.lastSearch.name && this.state.lastSearch.name !== this.state.name) {
                doIt = true
            }
            if (doIt) {
                this.props.setFilter({
                    loc: this.state.loc,
                    dist: this.state.dist,
                    pref: this.state.pref
                })
                this.getData()
            } else this.handleFilter()
        } else this.getData()
    }

    handleOptionSelect(node, cat) {
        //console.log(node, cat)
        if (this.state.pref && this.state.pref[node]) {
            if (cat === 'All') {
                this.setState({ pref: { [node]: [cat] } })
            } else {
                let cats = [...this.state.pref[node]]
                let idx = cats.indexOf('All')
                if (idx > -1) cats.splice(idx, 1)
                idx = cats.indexOf(cat)
                if (idx === -1) {
                    cats.push(cat)
                } else cats.splice(idx, 1)
                this.setState({ pref: { ...this.state.pref, [node]: cats } })
            }
        } else {
            this.setState({ pref: { [node]: [cat] } })
        }
    }

    handleResetOptions() {
        this.setState({ pref: null })
    }

    handleNameChange(name) {
        this.setState({ name })
    }

    handleInactive() {
        this.setState({ inactive: !this.state.inactive, pref: true })
    }

    handleFilter() {
        let filteredData = { venues: [], contributors: [] }
        if (this.props.data) {
            this.props.data.forEach(n => {
                if (n.nodetype === 'Venue') {
                    filteredData.venues.push(n)
                } else filteredData.contributors.push(n)
            })
        }
        this.setState({ filteredData })
    }

    handleOnNotificationsSelect() {
        console.log('here')
        this.handleNavigate('notifications')
    }

    handleOnPrefSelect() {
        this.handleNavigate('pref')
    }

    handleVenueSelected(v) {
        this.setState({ node: v, center: v.address.coord })
        this.scrollToRef(this.FanCards)
    }

    handleContributorSelected(c) {
        this.setState({ node: c, center: c.address.coord })
        this.scrollToRef(this.FanCards)
    }

    handleCloseMarker() {
        this.setState({ event: null, venue: null })
    }

    handleLeftMenu() {
        this.handleNavigate('login')
    }

    handleRightMenu() {
        this.setState({ node: null, center: this.props.myLoc })
    }

    handleGoToCard() {
        if (this.state.node && this.state.node.nodetype === 'Venue') {
            this.handleNavigate(`venue/${this.state.node.id}`)
        } else this.handleNavigate(`contributor/${this.state.node.id}`)
    }

    handleNavigate(page) {
        if (!this.state.isLoading) {
            this.props.onAddHistory('/')
            const { navigate } = this.props
            navigate(`/${page}`)
        }
    }

    handlePinSelected(pin) {
        this.setState({
            node: pin,
            center: pin && pin.address && pin.address.coord ? pin.address.coord : this.state.center
        })
    }

    handleTest() {
        if (this.props.isAuthenticated) {
            API.get('lt', `fanstuff?lat=${this.state.center.lat}&lng=${this.state.center.lng}&dist=${this.state.dist * 10}`)
                .then(resp => console.log(resp))
                .catch(e => console.log(e))
        }
        //API.get('lt', `fanstuff2?lat=${this.state.center.lat}&lng=${this.state.center.lng}&dist=${this.state.dist*10}`)
        //API.get('lt', `nodes?lat=${this.state.center.lat}&lng=${this.state.center.lng}&dist=${this.state.dist*10}&status=${this.state.inactive ? 'inactive' : 'active'}`)
    }

    handleNext() {
        this.setState({ isNext: true })
        this.getData()
    }

    getOptions() {
        if (this.props.isAuthenticated) {
            API.get('lt', 'options?prop=node')
                .then(resp => this.props.setOptions(resp.data || []))
                .catch(e => console.log(e))
        }
    }

    getData(location, distance) {
        const loc = location || this.props.myLoc || null
        const dist = distance || this.state.dist
        this.setState({ searchChanged: false, filteredData: null })
        //let qry = `node=${Object.keys(this.state.pref)[0]}`
        let qry = `status=${this.state.inactive ? 'inactive' : 'active'}`
        if (this.state.pref && Object.keys(this.state.pref) && Object.keys(this.state.pref).length > 0) qry += `&node=${Object.keys(this.state.pref)[0]}`
        if (loc && dist) qry += `&lat=${this.props.myLoc.lat}&lng=${this.props.myLoc.lng}&dist=${dist * 10}`
        if (this.state.name.length > 0) qry += `&name=${this.state.name}`
        if (this.state.next) qry += `&next=${this.state.next}`
        if (this.state.pref && Object.keys(this.state.pref) && Object.values(this.state.pref)[0] && Object.values(this.state.pref)[0].length > 0 && Object.values(this.state.pref)[0][0] !== 'All') qry += `&cat=${Object.values(this.state.pref)[0].toString()}`
        API.get('lt', `nodes?${qry}`)
            .then(resp => {
                this.props.setData(this.state.isNext ? [...(this.props.data || []), ...(resp.data || [])] : resp.data || [])
                this.setState({ lastSearch: { dist: this.state.dist, name: this.state.name }, isLoading: false, next: resp.next || null })
                this.handleFilter(resp.data || [])
            })
            .catch(err => {
                console.log(err)
                this.setState({ isLoading: false, next: null })
            })
    }

    getVenueMarker(category) {
        switch (category) {
            case 'Bar':
                return Beer
            case 'Church':
                return Church
            case 'Comedy Club':
                return Comedy
            case 'Nightclub':
                return Club
            case 'Restaurant':
                return Food
            case (category.indexOf('Band') > -1):
                return Music
            case 'DJ':
                return DJ
            case 'Solo Artist':
                return Music
            case 'Group':
                return Music
            case 'Duet':
                return Music
            case 'Comedian':
                return Comedy
            case 'Karaoke':
                return Mic
            case 'Trivia':
                return Mic
            default:
                return Venue
        }
    }

    getContributorMarker(summary) {
        switch (summary) {
            case (summary.indexOf('Band') > -1):
                return Music
            case 'DJ':
                return DJ
            case 'Solo Artist':
                return Music
            case 'Group':
                return Music
            case 'Duet':
                return Music
            case 'Comedian':
                return Comedy
            case 'Karaoke':
                return Mic
            case 'Trivia':
                return Mic
            default:
                return Crowd
        }
    }

    getMarkers() {
        if (this.state.filteredData) {
            let markers = []
            this.state.filteredData.venues.filter(d => d.address && d.address.coord).forEach((v, vidx) => {
                markers.push(<Marker onClick={() => this.handlePinSelected(v)} key={`vpin-${vidx}`}
                    position={v.address.coord}
                    animation={2}
                    title={v.name}
                    icon={{
                        url: this.getVenueMarker(v.category || ''),
                        scaledSize: { height: 40, width: 40 }
                    }} />)
            })
            this.state.filteredData.contributors.filter(d => d.address && d.address.coord).forEach((v, vidx) => {
                markers.push(<Marker onClick={() => this.handlePinSelected(v)} key={`cpin-${vidx}`}
                    position={v.address.coord}
                    animation={2}
                    title={v.name}
                    icon={{
                        url: this.getContributorMarker(v.category || ''),
                        scaledSize: { height: 40, width: 40 }
                    }} />)
            })
            return markers
        } else return []
    }

    getNoResults() {
        return <div className='Fan-NoMap-Container'>
            <div className='Fan-NoMap-Icon'><TbFilterSearch /></div>
            <div className='Fan-NoMap-Text'>No Matching Results</div>
            <div className='Fan-NoResults-Text'>Refine your search criteria</div>
        </div>
    }

    scrollToRef = ref => {
        ref.current.scroll({
            top: 0,
            behavior: 'smooth'
        })
    }

    render() {
        console.log(this.state, this.props)
        return <div className='Fan-Container'>
            <TopMenu
                mode='admin'
                loc={this.state.loc}
                locIsGood={this.state.locIsGood}
                onLocCheck={this.handleLocationCheck}
                onLocChanged={this.handleLocChanged}
                dist={this.state.dist}
                onDistChange={this.handleDistChanged}
                onClearDist={this.handleClearDist}
                onPref={this.handleOnPrefSelect}
                notifications={this.props.user ? (this.props.user.notifications ? this.props.user.notifications.length : true) : null}
                onNotifications={this.handleOnNotificationsSelect}
                user={this.props.user ? { firstName: this.props.user.firstName, image: this.props.user.image } : null}
                onSearch={this.handleDisplaySearch}
                options={this.props.options}
                onOptionsSelected={this.handleOptionSelect}
                pref={this.state.pref}
                displaySearch={this.state.displaySearch}
                displaySettings={this.state.displaySettings}
                onResetOptions={this.handleResetOptions}
                name={this.state.name}
                onNameChange={this.handleNameChange}
                inactive={this.state.inactive}
                onInactive={this.handleInactive}
                search
                range
                onGetMyLoc={this.handleGetMyLoc}
                onTest={this.handleTest} />
            <div className='Fan-No-Scroll'>
                <div className={`Fan-Map-Container Map-Half`}>
                    {
                        this.state.center ? <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
                            <GoogleMap
                                mapContainerStyle={{
                                    width: '100%',
                                    height: 'calc(55vh - 155px)',
                                    borderRadius: '5px'
                                }}
                                center={this.state.center}
                                zoom={this.state.zoom}>
                                <Marker
                                    position={this.props.myLoc}
                                    animation={1}
                                    title='GigIn from here.' />
                                {
                                    this.getMarkers()
                                }
                            </GoogleMap>
                        </LoadScript> : <div className='Fan-NoMap-Container'>
                            <div className='Fan-NoMap-Icon'><TbFilterSearch /></div>
                            <div className='Fan-NoMap-Text'>Set Your GigLocation!</div>
                        </div>
                    }
                </div>
                <div ref={this.FanCards} className={`Fan-Cards`}>
                    {
                        !this.state.node && this.state.next ? <div className='Fan-More-Container' onClick={() => this.handleNext()}>
                            <div className='Fan-More-Divider'></div>
                            <div className='Fan-More-Button'>More</div>
                            <div className='Fan-More-Divider'></div>
                        </div> : null
                    }
                    {
                        !this.state.node && this.state.filteredData && this.state.filteredData.venues && this.state.filteredData.venues.map((v, vidx) => {
                            return <div key={`mwvenue-${vidx}`} onClick={() => this.handleVenueSelected(v)} style={{ cursor: 'pointer' }}><MiniWide type='venue' data={v} near={this.props.user && this.props.user.dist && parseInt(this.props.user.dist) >= (v.distance || 0)} /></div>
                        })
                    }
                    {
                        !this.state.node && this.state.filteredData && this.state.filteredData.contributors && this.state.filteredData.contributors.map((v, vidx) => {
                            return <div key={`mwcontributor-${vidx}`} onClick={() => this.handleContributorSelected(v)} style={{ cursor: 'pointer' }}><MiniWide type='venue' data={v} near={this.props.user && this.props.user.dist && parseInt(this.props.user.dist) >= (v.distance || 0)} /></div>
                        })
                    }
                    {
                        this.state.node && this.state.node.nodetype === 'Venue' && <Card data={this.state.node} type='venue' onSelected={this.handleGoToCard} near={this.props.user && this.props.user.dist && parseInt(this.props.user.dist) >= (this.state.node.distance ? this.state.node.distance : 0)} />
                    }
                    {
                        this.state.node && this.state.node.nodetype !== 'Venue' && <Card data={this.state.node} type='contributor' onSelected={this.handleGoToCard} near={this.props.user && this.props.user.dist && parseInt(this.props.user.dist) >= (this.state.node.distance ? this.state.node.distance : 0)} />
                    }
                    {
                        (!this.props.data || (this.props.data && this.props.data.length === 0)) && this.getNoResults()
                    }
                </div>
            </div>
            <BottomMenu menu={this.state.menu} onLeft={this.handleLeftMenu} onRight={this.handleRightMenu} />
        </div>
    }
}

export default withRouter(Fan)